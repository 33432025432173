<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getBeforFormRule('sfaCenter_visitManage_visitControls_add');

    rule.forEach((item) => {
      const v = item;
      if (this.formConfig.type === 'view') {
        v.props = {
          ...v.props,
          disabled: true,
        };
        this.buttons.submit = false;
      }
    });
    this.rule = rule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      // request
      //   .get('/sfa/sfaVisitRoleConfigController/queryDetailById', {
      //     id: this.formConfig.id,
      //   })
      //   .then((res) => {
      //     if (res.success) {
      //       const { result } = res;
      //       this.row = result;
      //     }
      //   });
    }
  },
  methods: {
    // 提交
    submit() {
      // const formData = this.getFormData();
      // const param = formData;
      // if (param) {
      //   let url = '/sfa/sfaVisitRoleConfigController/save';
      //   let params = { ...param };

      //   if (this.formConfig.type === 'edit') {
      //     url = '/sfa/sfaVisitRoleConfigController/update';
      //     params = Object.assign(this.row, param);
      //   }

      //   request.post(url, params).then((res) => {
      //     if (res.success) {
      //       this.$message({
      //         type: 'success',
      //         message: '操作成功',
      //       });

      //       this.$emit('onClose');
      //       this.$emit('onGetList');
      //     }
      //   });
      // }
    },
  },
};
</script>
